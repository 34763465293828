var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "rounded-b-xl", attrs: { elevation: "2" } },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.documents, function (document) {
                      return _c(
                        "v-list-item",
                        {
                          key: document.id,
                          staticStyle: { "min-height": "36px !important" },
                          on: { click: function () {} },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-0" },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "font-weight-medium blue-grey--text darken-4",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFile(document)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        document.title
                                          ? document.title
                                          : document.original_name
                                      )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            { staticClass: "pa-0 ma-0" },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "mx-0",
                                  attrs: { row: "", wrap: "" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { small: "", icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(document)
                                        },
                                      },
                                    },
                                    [
                                      document.is_url == 1
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "primary lighten-1",
                                              },
                                            },
                                            [_vm._v("mdi-link")]
                                          )
                                        : _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "primary lighten-1",
                                              },
                                            },
                                            [_vm._v("mdi-download")]
                                          ),
                                    ],
                                    1
                                  ),
                                  _vm.role.name === "Administrator"
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: {
                                            small: "",
                                            icon: "",
                                            disabled: _vm.preventFileDelete,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteFile(document)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "error lighten-1",
                                              },
                                            },
                                            [_vm._v("mdi-delete")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { scrollable: "", fullscreen: "" },
                  model: {
                    value: _vm.showPdfPreviewDialog,
                    callback: function ($$v) {
                      _vm.showPdfPreviewDialog = $$v
                    },
                    expression: "showPdfPreviewDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: { dense: "", dark: "", color: "primary" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showPdfPreviewDialog = false
                                      _vm.activePdfLink = ""
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                              _c("v-toolbar-title", [
                                _vm._v(_vm._s(_vm.pdftitle)),
                              ]),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pa-0",
                          staticStyle: { height: "300px" },
                        },
                        [
                          _vm.showPdfPreviewDialog
                            ? _c("pdf-viewer", {
                                attrs: { fileUrl: _vm.activePdfLink },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { scrollable: "", fullscreen: "" },
                  model: {
                    value: _vm.showImageInFullScreen,
                    callback: function ($$v) {
                      _vm.showImageInFullScreen = $$v
                    },
                    expression: "showImageInFullScreen",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white",
                              attrs: { fab: "", elevation: "0" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { large: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showImageInFullScreen = false
                                    },
                                  },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _vm.showImageInFullScreen
                            ? _c("v-img", { attrs: { src: _vm.selectedImage } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }